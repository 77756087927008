import React, { useState } from "react";
import NewsForm from "../news-form-component/NewsForm";
import * as api from "../../../api/ApiRequest"
import { getFromStorage } from "../../utility/Storage";
import { showNotification } from "../../utility/showNotification";
import { useNavigate } from "react-router-dom";

export default function CreateNews() {
    // state for, user input type
    const [newsDetails, setNewsDetails] = useState({
        title: "",
        desc: "",
        source: "",
        link: "",
    });
    // state for, source input
    const [sourceState, setSourceState] = useState("");

    const navigate = useNavigate();

    const handleFormInput = (e) => {
        setNewsDetails((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSourceChange = (selected) => {
        setSourceState(selected);
        setNewsDetails((prev) => ({
            ...prev,
            source: selected?.value || "",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const errors = ["", null, undefined]

        if(errors.includes(newsDetails.title)){
            return showNotification("error", "Enter Title First")
        }

        if(errors.includes(newsDetails.source)){
            return showNotification("error", "Selecting Source Is Mandatory")
        }

        if(errors.includes(newsDetails.link)){
            return showNotification("error", "Link Is Mandatory")
        }

        const user = getFromStorage("dnr_a")

        const payload = {
            title: newsDetails.title.trim(),
            desc: newsDetails.desc.trim(),
            source: newsDetails.source,
            link: newsDetails.link,
            created_by: user.id
        }

        const res = await api.createNews(payload)

        if(res.flag === 'SUCCESS'){
            showNotification("success", res.msg)
            setNewsDetails({
                title: "",
                desc: "",
                source: "",
                link: "",
            });
            
            setSourceState("");
        } else {
            showNotification("error", res.msg)
        }
    };

    return (
        <NewsForm 
            newsDetails={newsDetails}
            sourceState={sourceState}
            handleFormInput={handleFormInput}
            handleSourceChange={handleSourceChange}
            handleSubmit={handleSubmit}
        >
            <button className="newsform__source-btn btn-submit" type="submit">Publish</button>
            <button className="newsform__source-btn btn-home" onClick={() => navigate("/")}>
                Back To Home Page
            </button>
        </NewsForm>
    );
}
