import React, { useEffect, useState } from "react";
import Navbar from "../admin-navbar/Navbar";
import * as api from "../../../api/ApiRequest";
import "./newslist.css";
import EachNewsRow from "./EachNewsRow";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone"
import NotificationContainer from "../../utility/NotificationContainer";

export default function NewsList() {
    let { state: prevDate } = useLocation();
    const [news, setNews] = useState([]);
    const [searchedDate, setSearchedDate] = useState({ from: "", to: "" });
    const [needUpdate, setNeedUpdate] = useState(false);

    useEffect(
        () => {
            if(searchedDate.from === "" || searchedDate.to === ""){             
                setSearchedDate({
                    from: prevDate?.from || moment().tz("Asia/Dhaka").format("YYYY-MM-DD"),
                    to: prevDate?.to || moment().tz("Asia/Dhaka").format("YYYY-MM-DD")
                });
                
                if(prevDate){
                    const previousSearchedFromDate = moment(prevDate?.from, "YYYY-MM-DD")
                        .tz("Asia/Dhaka")
                        .startOf("day")
                        .unix();
    
                    const previousSearchedToDate = moment(prevDate?.to, "YYYY-MM-DD")
                        .tz("Asia/Dhaka")
                        .startOf("day")
                        .unix();
                    
                    const payloadDate = `${previousSearchedFromDate}-${previousSearchedToDate}`
                    fetchData(payloadDate);
                } else {
                    const currentDate = moment().tz("Asia/Dhaka").startOf("day").unix();
    
                    fetchData(currentDate);
                }

                window.history.replaceState({}, document.title)
                setNeedUpdate(false)
            } else {
                const fromDate = moment(searchedDate.from, "YYYY-MM-DD").tz("Asia/Dhaka").startOf("day").unix();
                const toDate = moment(searchedDate.to, "YYYY-MM-DD").tz("Asia/Dhaka").startOf("day").unix();

                fetchData(`${fromDate}-${toDate}`)
                window.history.replaceState({}, document.title)
                setNeedUpdate(false)
            }

        },
        // eslint-disable-next-line
        [needUpdate, prevDate]
    );

    const fetchData = async (date) => {
        const res = await api.fetchNewsListForAdmin(date);
        setNews(res?.news || []);
    };

    const handleDateChange = (e) => {
        setSearchedDate(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };
    
    const handleSearchNewsDateWise = () => {
        const fromDate = moment(searchedDate.from, "YYYY-MM-DD").tz("Asia/Dhaka").startOf("day").unix();
        const toDate = moment(searchedDate.to, "YYYY-MM-DD").tz("Asia/Dhaka").startOf("day").unix();

        fetchData(`${fromDate}-${toDate}`)
    }

    const updateNewsList = () => {
        setNeedUpdate(true)
    }

    return (
        <>
            <Navbar />
            <NotificationContainer />

            <div className="news__wrapper">
                <div className="news__head">
                    <div className="news__title">News List</div>
                    <div>
                        <span>From Date: </span>
                        <input
                            type="date"
                            name="from"
                            value={searchedDate.from}
                            onChange={handleDateChange}
                        />
                        { "  " }
                        <span>To Date: </span>
                        <input
                            type="date"
                            name="to"
                            value={searchedDate.to}
                            onChange={handleDateChange}
                        />
                        { "  " }
                        <button className="news__btn-search" onClick={handleSearchNewsDateWise}>Search</button>
                    </div>
                </div>

                <div className="news__container">
                    <table className="news__table">
                        <thead className="news__table-head">
                            <tr>
                                <th
                                    style={{ width: "20%" }}
                                    className="news__table-header"
                                >
                                    News Title
                                </th>
                                <th
                                    style={{ width: "20%" }}
                                    className="news__table-header"
                                >
                                    News Description
                                </th>
                                <th className="news__table-header">Source</th>
                                <th className="news__table-header">
                                    Publish At
                                </th>
                                <th className="news__table-header">
                                    Publish By
                                </th>
                                <th className="news__table-header">
                                    Modified At
                                </th>
                                <th className="news__table-header">
                                    Modified By
                                </th>
                                <th className="news__table-header">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {news?.length === 0 ? (
                                <tr>
                                    <td className="news__table-nonews" colSpan={8}>No News</td>
                                </tr>
                            ) : (
                                news?.map((each) => (
                                    <EachNewsRow
                                        row={each}
                                        key={each.news_title}
                                        searchedDate={searchedDate}
                                        updateNewsList={updateNewsList}
                                    />
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
