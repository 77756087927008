import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./newsform.css";
import * as api from "../../../api/ApiRequest"
import Navbar from "../admin-navbar/Navbar";
import NotificationContainer from "../../utility/NotificationContainer";

export default function NewsForm({
    newsDetails,
    sourceState,
    handleFormInput,
    handleSourceChange,
    handleSubmit,
    children
}) {
    const [sources, setSources] = useState([])
    const MAX_TITLE_COUNT = 100
    const MAX_DESCRIPTION_COUNT = 1000

    useEffect(() => {
        fetchData()
    }, 
    // eslint-disable-next-line
    [])
    
    const fetchData = async () => {
        const res = await api.fetchMediaNames()
        
        const mediaList = res?.list?.map(each => {
            return {
                value: each.id,
                label: each.media_name
            }
        })

        setSources(mediaList)
    }

    const badgeColorForTitle = () => {
        if (newsDetails.title.length < (MAX_TITLE_COUNT - 50)) {
            return "newsform__title-badge-color";
        } else if (
            newsDetails.title.length >= (MAX_TITLE_COUNT - 50) &&
            newsDetails.title.length < (MAX_TITLE_COUNT - 20)
        ) {
            return "newsform__title-badge-warning";
        } else if (newsDetails.title.length >= (MAX_TITLE_COUNT - 20)) {
            return "newsform__title-badge-danger";
        }
    };

    const badgeColorForDesc = () => {
        if (newsDetails.desc.length < (MAX_DESCRIPTION_COUNT - 300)) {
            return "newsform__title-badge-color";
        } else if (
            newsDetails.desc.length >= (MAX_DESCRIPTION_COUNT - 300) &&
            newsDetails.desc.length < (MAX_DESCRIPTION_COUNT - 200)
        ) {
            return "newsform__title-badge-warning";
        } else if (newsDetails.desc.length >= (MAX_DESCRIPTION_COUNT - 200)) {
            return "newsform__title-badge-danger";
        }
    };

    return (
        <>
            <Navbar />
            <NotificationContainer />

            <h3 className="newsform__title">News Publish Page</h3>
            <form className="newsform__container" onSubmit={handleSubmit}>
                <div className="newsform__title-wrapper">
                    <div className="newsform__title-head">
                        <span>News Title - </span>
                        <span
                            className={`newsform__title-badge ${badgeColorForTitle()}`}
                        >
                            {newsDetails.title.length}/{MAX_TITLE_COUNT}
                        </span>
                    </div>
                    <input
                        className="newsform__title-input"
                        type="text"
                        name="title"
                        autoComplete="off"
                        maxLength={MAX_TITLE_COUNT}
                        value={newsDetails.title}
                        onChange={handleFormInput}
                    />
                </div>
                <div className="newsform__desc-wrapper">
                    <div className="newsform__desc-head">
                        <span>News Description - </span>
                        <span
                            className={`newsform__title-badge ${badgeColorForDesc()}`}
                        >
                            {newsDetails.desc.length}/{MAX_DESCRIPTION_COUNT}
                        </span>
                    </div>
                    <textarea
                        className="newsform__desc-input"
                        name="desc"
                        id=""
                        cols="30"
                        rows="5"
                        maxLength={MAX_DESCRIPTION_COUNT}
                        autoComplete="off"
                        value={newsDetails.desc}
                        onChange={handleFormInput}
                    ></textarea>
                </div>
                <div className="newsform__source-container">
                    <span>Link: </span>
                    <input
                        className="newsform__title-input"
                        type="text"
                        name="link"
                        autoComplete="off"
                        value={newsDetails.link}
                        onChange={handleFormInput}
                    />
                </div>
                <div className="newsform__source-container">
                    <div className="newsform__source-wrapper">
                        <span>Source: </span>
                        <Select
                            className="newsform__source-select"
                            maxMenuHeight={150}
                            options={sources}
                            isClearable={true}
                            isSearchable={true}
                            name="source"
                            value={sourceState}
                            onChange={(selection) =>
                                handleSourceChange(selection)
                            }
                        />
                    </div>
                    <div className="newsform__source-btns">
                        {children}
                        
                    </div>
                </div>
            </form>
        </>
    );
}
